import Home from "./pages/Home";
import "./App.css";
import CanvasBackground from "./components/CanvasBackground";
import SocialMediaLinksContainer from "./components/SocialMediaLinksContainer";

function App() {
  return (<>
        <CanvasBackground />
      <div className="App">
      <header className="container_title-normal">
        <h1>Neil Daimond</h1>
        {/* <img src="/images/icons/construction-1.gif" alt="Under Construction sign"/> */}
      </header>
        <Home />
        <SocialMediaLinksContainer/>
      </div>
  </>
  );
}

export default App;
