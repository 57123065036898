import SocialMediaLink from "./SocialMediaLink";

export default function SocialMediaLinksContainer() {
  const socialMediaChannels = [
    {
      linkName: "Youtube",
      linkIcon: "/images/icons/youtube-icon.svg",
      url: "https://www.youtube.com/@Neil-Daimond/videos",
    },
    {
      linkName: "Facebook",
      linkIcon: "/images/icons/facebook-icon.svg",
      url: "https://www.facebook.com/richard.gould.3701/photos",
    },
    {
      linkName: "Email",
      linkIcon: "/images/icons/email-icon-1.svg",
      url: "mailto:neildaimond83@yahoo.com",
    },
    {
      linkName: "Instagram",
      linkIcon: "/images/icons/instagram-icon.svg",
      url: "https://www.instagram.com/wakajawaka83/",
    },
  ];

  const links = socialMediaChannels.map(({ linkName, linkIcon, url }) => (
    <SocialMediaLink
      key={linkName + Math.random() * 100}
      linkName={linkName}
      linkIcon={linkIcon}
      url={url}
    />
  ));

  return (
    <footer
      className="container_external-links container_glass"
      aria-label="External links to my social media profiles"
    >
      <p className="ws socialblurb_ws">Check me out on social media:</p>
            {links}
  <small className="copyright_ws ws">© 2024 Gould</small>
    </footer>
  );
}
