export default function SocialMediaLink({
  linkName,
  linkIcon = "https://picsum.photos/200",
  url,
}) {
  return (
    <a
      href={url}
      target="_blank"
      aria-label={`External link to my ${linkName} page`}
      rel="noopener noreferrer"
      className="socialMediaLink"
    >
      <img
        src={linkIcon}
        alt={`A link to my content on ${linkName}`}
        className="socialMediaLinkIcon"
      />
    </a>
  );
}
