import React, { useState, useEffect, useRef } from 'react';

export default function CanvasBackground() {
    const canvasRef = useRef(null);
    const containerRef = useRef(null); // Define the container ref
    const defaultParticleCount = 300;
    const [particleCount, setParticleCount] = useState(defaultParticleCount); // Default number of particles to create
    const maxStrokesPerParticle = 500;
    const lineSize = 8;
    const fadeSpeedModifier = 500; //Default 100
    const maximumLineDistance = 300; // Default 100
    const particleSizeMultiplier = 8;
    const particleSpeedMultiplierX = 0.1;
    const particleSpeedMultiplierY = 0.1;
    const minimumParticleDistance = maximumLineDistance / particleSizeMultiplier;

    const particleArray = useRef([]); // Use a ref for particle array to persist between renders
    const coloursArray = [
        'rgba(255,255,255,0.1)',
        'rgba(255,255,255,0.1)',
        'rgba(182, 239, 255,0.1)',
        'rgba(0, 157, 255, 0.1)',
        'rgba(0, 191, 255,0.1)'
    ];
    // const coloursArray = ['rgba(255,255,255,0.3)','rgba(255,255,255,0.5)', 'rgba(182, 239, 255,0.8)', 'rgba(0, 157, 255, 0.5)', 'rgba(0, 191, 255,0.8)'];
    // const lineColours = ['rgb(255,255,255)', 'rgb(182, 239, 255)','rgb(0, 157, 255)', 'rgb(0, 191, 255)'];

    const cloudColoursArray = ['rgba(255,255,255,0.5', 'rgba(200,200,200,0.5)', 'rgb(150,150,150,0.5)', 'rgba(100,100,255,00.5)', 'rgba(182,239,255,0.5)'];

    // Function to resize canvas
    const resizer = (canvas) => {
        canvas.width = window.innerWidth * 2;
        canvas.height = window.innerHeight * 2; // Set height to twice the window height for scrollable effect
        const currentAspectRatio = window.innerWidth / window.innerHeight;

        if (currentAspectRatio < 1) {
            setParticleCount((prev) => Math.max(0, Math.floor(prev / 2))); // Prevent negative count
        } else {
            setParticleCount(defaultParticleCount);
        }
    };

    const getRandomColour = () => {
        const getColourIndexFromArray = Math.floor(Math.random() * coloursArray.length);
        return coloursArray[getColourIndexFromArray];
    };

    // Particle class definition moved outside the useEffect
    class Particle {
        constructor() {
            this.reset(canvasRef.current); // Initialize particle position
            this.initialSize = this.size;
            this.strokeConnections = 0;
        }

        reset(canvas) {
            this.x = Math.random() * canvas.width; // Random position within the canvas
            this.y = Math.random() * canvas.height; // Random position within the canvas
            this.size = Math.random() * particleSizeMultiplier + 1; // Random size
            this.speedX = Math.random() * particleSpeedMultiplierX - 0.5; // Random speed in x direction
            this.speedY = Math.random() * particleSpeedMultiplierY - 0.5; // Random speed in y direction
            this.color = getRandomColour();
            this.strokeConnections = 0;
        }

        update() {
            // Update particle position based on speed
            this.x += this.speedX;
            this.y += this.speedY;

            // Wrap particle when it goes off screen
            if (this.x < 0) this.x = canvasRef.current.width;
            if (this.x > canvasRef.current.width) this.x = 0;
            if (this.y < 0) this.y = canvasRef.current.height;
            if (this.y > canvasRef.current.height) this.y = 0;
        
        //ALTERNATIVE Below > Destroy when they go off screen.
            // if (this.x < 0 || this.x > canvasRef.current.width || this.y < 0 || this.y > canvasRef.current.height) {
            //     this.reset(canvasRef.current);
            // }

            // Shrink the particles over time
            const fadeSpeed = this.initialSize / fadeSpeedModifier;
            if (this.size > 0.2) {
                    this.size -= fadeSpeed;} 
            else {
                this.reset(canvasRef.current);
            }
        }

        draw(ctx) {
            ctx.fillStyle = this.color;
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
            ctx.fill();
        }
    }

    // Function to draw connections between particles
    const drawConnections = (ctx) => {
        for (let i = 0; i < particleArray.current.length; i++) {
            for (let j = i + 1; j < particleArray.current.length; j++) {
                const dx = particleArray.current[i].x - particleArray.current[j].x;
                const dy = particleArray.current[i].y - particleArray.current[j].y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                // Draw line if the distance is within range
                if (
                    distance < maximumLineDistance &&
                    particleArray.current[i].strokeConnections < maxStrokesPerParticle &&
                    particleArray.current[j].strokeConnections < maxStrokesPerParticle
                ) {
                    ctx.beginPath();
                    ctx.strokeStyle = particleArray.current[i].color;
                    ctx.lineWidth = lineSize; // Line width
                    ctx.moveTo(particleArray.current[i].x, particleArray.current[i].y);
                    ctx.lineTo(particleArray.current[j].x, particleArray.current[j].y);
                    ctx.stroke();

                    particleArray.current[i].strokeConnections++;
                    particleArray.current[j].strokeConnections++;
                }
            }
        }
    };

    // Animation function
    const animate = (ctx) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // Clear the canvas
        ctx.fillStyle = 'rgb(0,0,0)';
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        for (let particle of particleArray.current) {
            particle.update();
            particle.draw(ctx);
        }
        drawConnections(ctx); // Draw connections between particles

        requestAnimationFrame(() => animate(ctx)); // Loop the animation
    };

    // Function to create and initialize particles
    const createParticles = () => {
        particleArray.current.length = 0; // Clear existing particles
        for (let i = 0; i < particleCount; i++) {
            particleArray.current.push(new Particle());
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Resize canvas
        resizer(canvas);
        const handleResize = () => resizer(canvas);

        // Initial particle creation
        createParticles(); // Call createParticles on mount
        animate(ctx); // Start the animation

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Only run on mount

    // Re-create particles whenever the particle count changes
    useEffect(() => {
        createParticles(); // Ensure particles are created on count change
    }, [particleCount]);

    return (
        // <div ref={containerRef} className="canvasContainer" style={{ position: 'relative', overflow: 'hidden' }}>
        <canvas ref={canvasRef} className="backgroundCanvas" />
        // </div>
    );
}