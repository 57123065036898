
import { useEffect } from "react";
import SocialMediaLinksContainer from "../components/SocialMediaLinksContainer";

let currentAge = 34;


export default function Home() {

useEffect(() => {
  function calculateAge() {
    const birthDate = new Date("1983-06-02");
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();
  
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    console.log(birthDate, currentDate);
    return currentAge = age;
  }
},[])

  return (
    <main className=" container_home">
      <section className="container_main-img">
        <h2>
          Welcome to the homepage of Wale's premier Neil Diamond tribute act
        </h2>
        <img
          src="images/photos/cutout-light-sqcrop.png"
          className="mainProfilePhoto container_glass"
        />
      </section>
      <article className="container_glass container_main-blurb">
        <section>
          <h2>Meet Neil Daimond: The Uncanny Voice of Neil Diamond</h2>
          <p>
            Hi, I'm Richard Gould, the man behind Neil Daimond. I'm
            41 years old and have been playing guitar & singing most of my life both personally and professionally. 
            My portrayal of Neil Diamond has taken me across Britain, and I've won numerous awards for my performances,
            including the prestigious Search4Stars competition, where I won
            'Best Tribute Act.' Many are amazed at the uncanny similarity of my
            vocals to the real Neil Diamond, something that has become a
            defining feature of my act.
          </p>
        </section>
        <section>
          <h3>From Hendrix to Diamond: The Journey Begins</h3>
          <p>
            I picked up the guitar at the age of 11, inspired by the
            mind-blowing music of Jimi Hendrix. As I grew musically, I was
            deeply influenced by artists like Emerson, Lake & Palmer, Alice
            Cooper, and Steve Vai. While my tastes were eclectic, Neil Diamond's
            music always held a special place in my heart. No matter what I
            sang, people kept telling me I sounded just like him — a comment I
            would hear again and again over the years.
          </p>
        </section>
        <section>
          <h3>A Star Is Born in Portugal</h3>
          <p>
            The pivotal moment for my tribute career came during a holiday in
            Portugal. At 36 years old, I decided to give karaoke a shot,
            performing one of Neil Diamond's classic hits. The reaction was
            overwhelming — I became an instant sensation at the bar, performing
            every night for the rest of the holiday. That experience planted the
            seed for what would eventually become my full-fledged tribute act.
          </p>
        </section>
        <section>
          <h3>The Road to Success</h3>
          <p>
            Upon returning home, I committed to refining my voice and
            performance, focusing specifically on Neil Diamond. In 2023, I
            entered Search4Stars, winning 'Best Tribute Act' and officially
            launching Neil Daimond. My voice had always had an uncanny
            resemblance to Diamond's, and as my tribute act grew, it became
            clear that I was onto something special.
          </p>
        </section>
        <section>
          <h3>Musical Roots and Bands</h3>
          <p>
            Long before my tribute act, I was honing my skills as a guitarist
            and vocalist in the music scene. During my teens, I jammed with
            friends who later formed Funeral For a Friend, and in my 20s, I
            joined Rhondda Records as part of their artist roster. Through
            Rhondda, I learned to produce my own music and expanded my catalog
            with a unique style of guitar-driven compositions. It was here that
            my vocal resemblance to Neil Diamond first became apparent, and
            listeners couldn't believe it was me until they heard my speaking
            voice after a song.
          </p>
        </section>
        <section>
          <h3>Band Involvement</h3>
          <p>
            I've been involved in several bands over the years, including as a
            founding member of Cardiff's Capitol Confusion and, more recently,
            as part of The Pillars of Creation, where I contribute as both a
            guitarist and supporting vocalist. These experiences, along with my
            solo catalog, have helped me develop my musical versatility and
            refine my craft, but my voice as Neil Diamond has become the
            defining aspect of my career.
          </p>
        </section>
        <section>
          <h3>Continuing the Legacy</h3>
          <p>
            Now, as Neil Daimond, I perform all over Britain, winning over
            audiences with every show. My tribute act continues to grow, and the
            feedback is always the same: people are shocked by how closely I
            sound like Neil Diamond. I take pride in delivering memorable
            performances that leave audiences in awe, and I am constantly
            striving to perfect my act.
          </p>
        </section>
        <section>
          <p>
            Whether you're a die-hard fan of Neil Diamond or just discovering
            his music, I hope to bring that same passion and joy to every stage
            I step onto.
          </p>
        </section>
      </article>
    </main>
  );
}
